import { useMemo } from 'react';
import { searchParamHash } from '../App';

export interface SearchParamHashType {
  [key: string]: string;
}
interface SearchParamsType {
  [key: string]: string;
}

function useSearchParams(): SearchParamsType {
  const searchParams = useMemo(() => {
    const alteredSearchParams = Array.from(
      new URLSearchParams(location.search).entries()
    ).map(([key, value]) => {
      const lowerCasedKey = key.toLowerCase();
      return [searchParamHash[lowerCasedKey] || key, value];
    });

    const paramsObject = Object.fromEntries(alteredSearchParams);
    return paramsObject;
  }, []);

  return searchParams;
}

export default useSearchParams;
