import SHA256 from 'crypto-js/sha256';
import { testerDomains, testerPhones } from './constants';

export const hashStringWithSHA256 = (input: string): string =>
  SHA256(input).toString();

export const hashEmailStringWithSHA256 = (email: string): string => {
  const stabilizedEmail = email.replace(/[ .]/g, '').toLowerCase();

  return SHA256(stabilizedEmail).toString();
};
export const getDomainPart = (email: string) =>
  email.split('@')[1]?.split('.')[0] || '';

export const isTesterEmail = (email: string): boolean =>
  testerDomains.includes(getDomainPart(email));

export const isTesterPhone = (phone: string): boolean =>
  testerPhones.includes(phone);
