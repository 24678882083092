import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';

const NewLinkSent = () => {
  return (
    <MainTemplate>
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Oops! Your link was expired.
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: 'center', maxWidth: 250, margin: '0 auto 50px' }}
      >
        A new link has been sent.
      </Typography>
    </MainTemplate>
  );
};

export default NewLinkSent;
