import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';
import { FC } from 'react';
import Button from '@mui/material/Button';
import { Pages } from '../App';

interface ErrorPageProps {
  serverErrorMessage: string;
  setPage: (value: Pages) => void;
}
const ErrorPage: FC<ErrorPageProps> = ({ serverErrorMessage, setPage }) => {
  const linkParams = new URLSearchParams({
    subject: '[register.mobilefit.sizestream.com] Invalid share request info',
    body: `
===
Link in question:
${location}`
  });
  const link = 'mailto:support@sizestream.com?' + linkParams.toString();

  const restartHandler = async () => {
    setPage(Pages.Landing);
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={restartHandler}
      >
        Start over
      </Button>
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Oops!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: 'center', maxWidth: 250, margin: '0 auto 50px' }}
      >
        {serverErrorMessage ?? 'The link that brought you here has a problem'}
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Please check with the organization that shared the link with you.
        Contact <a href={link}>support@sizestream.com</a> if further assistance
        is needed.
      </Typography>
    </MainTemplate>
  );
};

export default ErrorPage;
