import * as React from 'react';
import { useEffect } from 'react';
import { consentConfig, gtagId } from './constants';
import ReactGA from 'react-ga4';

interface GoogleTagManagerSetterProps {
  consentGiven: boolean;
}

export const GoogleTagManagerSetter: React.FC<GoogleTagManagerSetterProps> = ({
  consentGiven
}) => {
  useEffect(() => {
    if (consentGiven) {
      ReactGA.gtag('consent', 'default', consentConfig);

      ReactGA.initialize(gtagId);
    }
  }, [consentGiven]);

  return null;
};
