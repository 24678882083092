import { FC } from 'react';
import Box from '@mui/material/Box';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material';

const MainTemplate: FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: 20,
        maxWidth: 490,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 400
        }
      }}
    >
      <Box
        component="img"
        sx={{
          maxWidth: 300,
          [theme.breakpoints.down('sm')]: {
            padding: '60px 5% 0 5%',
            maxWidth: '90%'
          }
        }}
        src="/logo.png"
      />
      <Box
        sx={{
          background: blueGrey[50],
          padding: '40px 0',
          position: 'relative',
          [theme.breakpoints.up('sm')]: {
            padding: '40px 60px'
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainTemplate;
