import React, { FC } from 'react';
import MainTemplate from '../components/MainTemplate';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { PageProps, Pages } from '../App';

const Done: FC<PageProps> = ({ setPage }) => {
  const restartHandler = async () => {
    setPage(Pages.Landing);
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={restartHandler}
      >
        Start over
      </Button>
      <Box
        sx={{
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          You&apos;re all done!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: '250px',
            margin: '0 auto 40px',
            lineHeight: '1.4'
          }}
        >
          You have successfully scanned and shared your measurements.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        ></Box>
      </Box>
    </MainTemplate>
  );
};

export default Done;
