import axios, { AxiosError, AxiosResponse } from 'axios';

export type RequestError = AxiosError | Error;

export type Response<T> = Promise<AxiosResponse<T> | RequestError>;

export type RequestResponse<T, D> = (payload: D) => Response<T>;

export type RequestConfig<T, C> = (payload: C) => Response<T>;

export type ShareParams = {
  appId: string;
  name: string;
  scopes?: string[];
  terms?: string;
  share_checkbox_checked?: boolean;
};

export function isShareParams(
  params: Record<string, unknown>
): params is ShareParams {
  return 'appId' in params && 'name' in params;
}

export type StartMobileExperiencePayload = {
  phone?: string;
  email?: string;
  shareParams?: ShareParams;
  origin_url?: string; // origin_url is the front end webpage base url, passed to backend to help with callback handling
};

export const startMobileExperience: RequestResponse<
  null,
  StartMobileExperiencePayload
> = async (payload) => {
  try {
    const res = await axios.post<null>('api/start', payload);
    return res;
  } catch (err: any) {
    if (err?.response?.data) {
      return err.response;
    }
    const error = err as Error | AxiosError;
    return error;
  }
};

interface User {
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
}

interface RegisterParams {
  phone?: string;
  email?: string;
  firstName: string;
  lastName: string;
}

export const registerUser: RequestResponse<User, RegisterParams> = async (
  payload
) => {
  try {
    const res = await axios.post<User>('api/register', payload);
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

export type getAppIdPayload = {
  appid: string;
};

interface appName {
  appName: string;
}

// TODO change this to an appropriate GET
export const getAppInfo: RequestConfig<null, getAppIdPayload> = async (
  payload
) => {
  try {
    const res = await axios.post<appName>('api/app', payload);
    return res;
  } catch (err: any) {
    if (err?.response?.data) {
      return err.response;
    }
    const error = err as Error | AxiosError;
    return error;
  }
};
