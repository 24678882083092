export const gtagId = 'G-FXESZ3RWH0';
export const consentKey = 'consent';

export enum ConsentStatus {
  Granted = 'granted',
  Denied = 'denied'
}
export enum GAEvents {
  SignInPhone = 'pushed_continue_on_phone_sign_in',
  SignInEmail = 'pushed_continue_on_email_sign_in',
  SignUp = 'pushed_continue_on_share_and_sign_up',
  StartOverRegisterPage = 'pushed_start_over_on_sign_up',
  StartOverStartPage = 'pushed_start_over_on_link_sent',
  Resend = 'pushed_resend',
  WithCompanyInfo = 'arrived_with_company_information',
  WithoutCompanyInfo = 'arrived_without_company_information',
  PrivacyTermsAgree = 'checked_sizestream_i_agree',
  TermsOfServiceLink = 'pushed_sizestream_tos_link'
}
export const consentConfig = {
  ad_storage: ConsentStatus.Denied,
  ad_user_data: ConsentStatus.Denied,
  ad_personalization: ConsentStatus.Denied,
  analytics_storage: ConsentStatus.Granted
};
export const testerDomains = ['mailinator', 'sizestream', 'abtollc'];
export const testerPhones = [''];
