import * as React from 'react';
import { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Modal from '@mui/material/Modal';
import { consentKey, ConsentStatus } from '../gtag/constants';
import { GoogleTagManagerSetter } from '../gtag/GoogleTagManagerSetter';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';

const useStyles = createUseStyles({
  modalWrapper: {
    color: '#fff',
    backgroundColor: '#000',
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    padding: '16px 45px 16px 20px',
    zIndex: 100,
    transition: 'transform .25s ease-in-out,opacity .25s ease-in-out',
    transform: 'translateY(100%)',
    fontFamily: 'Roboto, Helvetica, Arial',
    opacity: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '16px 20px 16px 20px'
    }
  },
  showModal: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  closeButton: {
    position: 'absolute',
    top: '50%',
    right: 15,
    width: 22,
    height: 22,
    zIndex: 100,
    background: 'transparent',
    transform: 'translateY(-50%)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:before, &:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: 2,
      top: 10,
      left: 0,
      background: '#fff',
      borderRadius: '50px'
    },
    '&:before': {
      transform: 'rotate(45deg)'
    },
    '&:after': {
      transform: 'rotate(-45deg)'
    },
    '@media (max-width: 768px)': {
      top: 30
    }
  },
  modalInfo: {
    marginRight: 15,
    fontSize: 14,
    '& span': {
      paddingRight: 10
    },
    '& a': {
      color: '#fff'
    },
    '@media (max-width: 768px)': {
      marginRight: 30,
      marginBottom: 15
    }
  },
  modalButtons: {
    display: 'flex',
    minWidth: 180,
    '& button': {
      margin: '0 5px'
    },
    '@media (max-width: 768px)': {
      width: '100%',
      '& button': {
        flex: 1
      }
    }
  },
  settingModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    backgroundColor: '#eceff1',
    padding: 20,
    fontFamily: 'Roboto, Helvetica, Arial'
  },
  settingCloseButton: {
    top: 20,
    transform: 'none',
    '&:before, &:after': {
      background: '#000'
    }
  },
  checkboxWrapper: {
    margin: '40px 0 25px'
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #dee2e6',
    padding: '15px 0',
    '& p': {
      fontSize: 12,
      marginRight: 20
    }
  }
});
interface SettingModalProps {
  onSave: (isAccepted: boolean) => void;
  onClose?: () => void;
  open: boolean;
}
interface Checkbox {
  label: string;
  text: string;
  checked: boolean;
  disabled?: boolean;
}

const SettingModal: React.FC<SettingModalProps> = ({
  onSave,
  onClose,
  open
}) => {
  const styles = useStyles();

  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([
    {
      label: 'Essential Cookies',
      text: 'These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.',
      checked: true,
      disabled: true
    },
    {
      label: 'Enable Analytics Cookies',
      text: 'These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.',
      checked: false
    }
  ]);
  const handleCheckboxChange = (index: number) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox, i) =>
        i === index ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleSave = () => {
    onSave(checkboxes.every((c) => c.checked));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.settingModal}>
        <Box
          className={`${styles.closeButton} ${styles.settingCloseButton}`}
          onClick={onClose}
        />
        <Typography variant={'h5'}>Advanced Cookie Settings</Typography>
        <Box className={styles.checkboxWrapper}>
          {checkboxes.map((c, idx) => (
            <label key={idx} className={styles.label}>
              <Box>
                <Typography variant="subtitle1" display={'inline'}>
                  {c.label}
                </Typography>
                <Typography variant="body1" display={'block'}>
                  {c.text}
                </Typography>
              </Box>
              <Switch
                onChange={() => handleCheckboxChange(idx)}
                checked={c.checked}
                disabled={c.disabled}
              />
            </label>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            type="submit"
            sx={{ marginBottom: 2, background: '#fff', color: '#000' }}
            color="inherit"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const CookieModal: React.FC = () => {
  const styles = useStyles();

  const isConsentGranted = !!localStorage.getItem(consentKey);

  const [isShowModal, setIsShowModal] = useState<boolean>(!isConsentGranted);
  const [isGACookieAccepted, setIsGACookieAccepted] =
    useState<boolean>(isConsentGranted);
  const [isShowSettingModal, setIsShowSettingModal] = useState<boolean>(false);

  const handleAccept = () => {
    setIsShowModal(false);
    setIsGACookieAccepted(true);
    localStorage.setItem(consentKey, ConsentStatus.Granted);
  };

  const handleSettingSave = (isAccepted: boolean) => {
    setIsShowModal(false);
    setIsShowSettingModal(false);
    setIsGACookieAccepted(isAccepted);
    if (isAccepted) {
      localStorage.setItem(consentKey, ConsentStatus.Granted);
    }
  };

  const onHideSettingModal = useCallback(
    () => setIsShowSettingModal(false),
    []
  );

  return (
    <>
      <Box
        className={`${styles.modalWrapper} ${isShowModal && styles.showModal}`}
      >
        <Box
          className={styles.closeButton}
          onClick={() => setIsShowModal(false)}
        ></Box>
        <Box className={styles.modalInfo}>
          <Typography variant="body2" display={'inline'} gutterBottom>
            We use cookies on our website to see how you interact with it. By
            accepting, you agree to our use of such cookies.
          </Typography>
          <Link
            href="https://www.sizestream.com/privacypolicy"
            target="_blank"
            rel="noreferrer"
          >
            See Privacy Policy
          </Link>
        </Box>
        <Box className={styles.modalButtons}>
          <LoadingButton
            type="submit"
            sx={{ marginBottom: 2, background: '#eceff1', color: '#000' }}
            color="inherit"
            variant="contained"
            onClick={() => setIsShowSettingModal(true)}
          >
            Settings
          </LoadingButton>
          <LoadingButton
            type="submit"
            sx={{ marginBottom: 2, background: '#fff', color: '#000' }}
            color="inherit"
            variant="contained"
            onClick={handleAccept}
          >
            Accept
          </LoadingButton>
        </Box>
      </Box>
      <SettingModal
        open={isShowSettingModal}
        onClose={onHideSettingModal}
        onSave={handleSettingSave}
      />
      <GoogleTagManagerSetter consentGiven={isGACookieAccepted} />
    </>
  );
};
